<template> 
    <div v-if="bannerFiles.length && imgUrl" class="grid justify-center mb-4">
        <transition :name="bannerTransitionName" mode="in-out">
            <div :key="imgUrl" class="col-start-1 row-start-1">
                <a v-if="targetUrl" :href="targetUrl" target="_blank">
                    <img :src="imgUrl" :alt="altText" :class="bannerImgMaxSize" class="w-full h-auto">
                </a>
                <img v-else :src="imgUrl" :alt="altText" :class="bannerImgMaxSize" class="w-full h-auto">
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, Ref, watch, computed } from "vue";
import { getCurrentBreakpoint } from "../common/utils/shop";
import { useBannerStore } from "../stores/banner/bannerStore";
import { useRouter } from "vue-router";
import ServerError from "../common/error/ServerError";
import { useToastStore } from "../stores/toast/toastStore";
import { BannerFileTypeEnum } from "../store/bannerFiles/BannerFileTypeEnum";

const router = useRouter();
const bannerStore = useBannerStore();
const toastStore = useToastStore();

const targetUrl: Ref<string> = ref('');
const imgUrl: Ref<string> = ref('');
const bannerType: Ref<string> = ref('');
const currentBreakpoint: Ref<string> = ref('');
const bannerFiles: Ref<any[]> = ref([]);
const currentBannerIndex: Ref<number> = ref(0);
const altText: Ref<string> = ref('');
const bannerInterval: Ref<ReturnType<typeof setInterval> | undefined> = ref(undefined);
const rotationInterval = 15000;

const props = defineProps<{ type: string }>();

onMounted(() => {
    currentBreakpoint.value = getCurrentBreakpoint();
    bannerType.value = calculateBannerType(props.type);
    window.addEventListener('resize', updateBreakpoint);
});

const bannerImgMaxSize = computed(() => {
    if (bannerType.value === BannerFileTypeEnum.UNIVERSAL || 
        bannerType.value === BannerFileTypeEnum.SEARCH_PAGE_MOBILE || 
        bannerType.value === BannerFileTypeEnum.MAIN_PAGE_MOBILE) {
        return 'mobile-banner-size';
    }

    if (bannerType.value === BannerFileTypeEnum.SEARCH_PAGE || 
        bannerType.value === BannerFileTypeEnum.MAIN_PAGE) {
        return 'desktop-banner-size';
    }

    return '';
});

const bannerTransitionName = computed(() => {
    return bannerType.value === BannerFileTypeEnum.UNIVERSAL ? 'slide-right' : 'slide';
});

const getBannerFiles = async () => {
    try {
        const params: any = { type: bannerType.value };

        if (bannerType.value === BannerFileTypeEnum.SEARCH_PAGE || bannerType.value === BannerFileTypeEnum.SEARCH_PAGE_MOBILE) {
            params.searchPageType = router.currentRoute.value.name as string;
        }

        bannerFiles.value = await bannerStore.getActiveBannerFiles(router, params);
    } catch (error) {
        toastStore.addErrorToast((error as ServerError).toString());
    }
};

const calculateBannerType = (type: string) => {
    if (type === BannerFileTypeEnum.MAIN_PAGE || type === BannerFileTypeEnum.MAIN_PAGE_MOBILE) {
        return isMobile() ? BannerFileTypeEnum.MAIN_PAGE_MOBILE : BannerFileTypeEnum.MAIN_PAGE;
    }

    if (type === BannerFileTypeEnum.SEARCH_PAGE || type === BannerFileTypeEnum.SEARCH_PAGE_MOBILE) {
        return isMobile() ? BannerFileTypeEnum.SEARCH_PAGE_MOBILE : BannerFileTypeEnum.SEARCH_PAGE;
    }

    if (type === BannerFileTypeEnum.UNIVERSAL) {
        return BannerFileTypeEnum.UNIVERSAL;
    }

    return BannerFileTypeEnum.MAIN_PAGE;
};

const isMobile = () => {
    return currentBreakpoint.value === "sm" || currentBreakpoint.value === "md";
};

const updateBreakpoint = async () => {
    currentBreakpoint.value = getCurrentBreakpoint();
    bannerType.value = calculateBannerType(bannerType.value);
};

const setBannerFiles = async () => {
    resetAndClearInterval();
    currentBannerIndex.value = 0;
    
    if (bannerFiles.value.length > 0) {
        setBannerFile(0);

        if (bannerFiles.value.length > 1) {
            currentBannerIndex.value += 1;
            startBannerRotation();
        }
    }
}

const startBannerRotation = () => {
    resetAndClearInterval();

    if (bannerFiles.value.length > 1) {
        bannerInterval.value = setInterval(() => {
            setBannerFile(currentBannerIndex.value);
            currentBannerIndex.value = (currentBannerIndex.value + 1) % bannerFiles.value.length;
        }, rotationInterval);
    }
};

const setBannerFile = async (index: number) => {
    imgUrl.value = bannerFiles.value[index].fileUrl;
    targetUrl.value = bannerFiles.value[index].targetUrl || '';
    altText.value = `banner-${index + 1}`;
};

const resetAndClearInterval = () => {
    clearInterval(bannerInterval.value);
    bannerInterval.value = undefined;
};

watch(bannerType, async () => {
    await getBannerFiles();
    setBannerFiles();
});

onUnmounted(() => {
    bannerFiles.value = [];
    window.removeEventListener('resize', updateBreakpoint);
    resetAndClearInterval();
});
</script>

<style scoped>
.slide-enter-active {
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.slide-enter-from {
    opacity: 0;
    transform: translateX(-50%);
}

.slide-enter-to {
    opacity: 1;
    transform: translateX(0);
}

.slide-right-enter-active {
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.slide-right-enter-from {
    opacity: 0;
    transform: translateX(50%);
}

.slide-right-enter-to {
    opacity: 1;
    transform: translateX(0);
}

.mobile-banner-size {
    max-width: 336px;
    max-height: 280px;
}

.desktop-banner-size {
    max-width: 980px;
    max-height: 120px;
}
</style>
