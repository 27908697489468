<template>
    <div
        v-if="activeCart !== undefined && activeCart.getSize()"
        class="bg-white p-4 flex flex-col border border-gray rounded"
    >
        <div class="flex items-end print:hidden mb-4">
            <shop-input
                v-model="commonProfitPercentage"
                type="number"
                :label="
                    trans(
                        'common_profit_percentage',
                        'Common profit percentage: ',
                        'quote'
                    )
                "
                class="max-w-[150px] mr-3"
                border
            />
            <button
                class="btn btn-rounded btn-primary"
                @click="applyCommonProfitPercentageOnAllRows"
            >
                {{
                    trans(
                        "common_profit_percentage_button",
                        "Apply on all rows",
                        "quote"
                    )
                }}
            </button>
        </div>
        <div
            class="xl:flex flex-col no-wrap xl:flex-row justify-between border-b-2 border-gray uppercase font-bold hidden print:flex"
        >
            <div class="w-full py-1 px-2">
                {{ trans("cart_summary_name", "Name", "quote") }}
            </div>
            <div class="w-full py-1 px-2">
                {{ trans("cart_summary_size", "Size", "quote") }}
            </div>
            <div v-if="hasTyreLabeling()" class="w-full py-1 px-2">
                {{ trans("cart_summary_label", "Tyre labeling", "quote") }}
            </div>
            <div class="w-full py-1 px-2">
                {{ trans("cart_summary_quantity", "Quantity", "quote") }}
            </div>
            <div class="w-full py-1 px-2">
                {{
                    trans(
                        "cart_summary_gross_unit_price",
                        "Gross unit price",
                        "quote"
                    )
                }}
            </div>
            <div class="w-full text-center py-1 px-2 print:hidden">
                {{
                    trans(
                        "cart_summary_profit_percentage",
                        "Profit percentage",
                        "quote"
                    )
                }}
            </div>
            <div class="w-full text-center py-1 px-2 print:hidden">
                {{
                    trans(
                        "cart_summary_gross_unit_price_with_profit",
                        "Gross unit price with profit",
                        "quote"
                    )
                }}
            </div>
            <div class="w-full py-1 px-2 xl:text-right">
                {{ trans("cart_summary_total_gross", "Total gross", "quote") }}
            </div>
        </div>
        <div
            v-for="product in quoteProducts"
            :key="product.id"
            class="flex flex-col no-wrap gap-2 xl:flex-row justify-between border-gray border-b-1"
        >
            <div class="flex flex-row gap-4 w-full py-1 px-2">
                <div
                    class="self-center w-full uppercase font-bold xl:hidden print:hidden"
                >
                    {{ trans("cart_summary_name", "Name", "quote") }}
                </div>
                <div
                    class="self-center w-full text-right md:text-left"
                    v-html="product.name"
                />
            </div>
            <div class="flex flex-row gap-4 w-full py-1 px-2">
                <div
                    class="self-center w-full uppercase font-bold xl:hidden print:hidden"
                >
                    {{ trans("cart_summary_size", "Size", "quote") }}
                </div>
                <div
                    class="self-center w-full text-right md:text-left"
                    v-html="product.size"
                />
            </div>
            <div
                v-if="hasTyreLabeling()"
                class="flex flex-row gap-4 w-full py-1 px-2"
            >
                <div
                    class="self-center w-full uppercase font-bold xl:hidden print:hidden"
                >
                    {{ trans("cart_summary_label", "Tyre labeling", "quote") }}
                </div>
                <div class="self-center w-full text-right md:text-left">
                    <span
                        v-if="
                            product.fuelEfficiency &&
                            product.fuelEfficiency != ''
                        "
                    >
                        <i class="marso-icon-fuel_label" />
                        {{ product.fuelEfficiency }}
                    </span>
                    <br />
                    <span
                        v-if="
                            product.wetGripClass && product.wetGripClass != ''
                        "
                    >
                        <i class="marso-icon-rain_label" />
                        {{ product.wetGripClass }}
                    </span>
                    <br />
                    <span
                        v-if="
                            product.noiseMeasurementValue &&
                            product.noiseMeasurementValue != 0
                        "
                    >
                        <i class="marso-icon-noise_label" />
                        {{ product.noiseMeasurementValue }}dB
                    </span>
                    <br />
                </div>
            </div>
            <div class="flex flex-row gap-4 w-full py-1 px-2">
                <div
                    class="self-center w-full uppercase font-bold xl:hidden print:hidden"
                >
                    {{ trans("cart_summary_quantity", "Quantity", "quote") }}
                </div>
                <div class="self-center w-full text-right md:text-left">
                    {{ product.quantity }}
                </div>
            </div>
            <div class="flex flex-row gap-4 w-full py-1 px-2 print:hidden">
                <div
                    class="self-center w-full uppercase font-bold xl:hidden print:hidden"
                >
                    {{
                        trans(
                            "cart_summary_gross_unit_price",
                            "Gross unit price",
                            "quote"
                        )
                    }}
                </div>
                <div class="self-center w-full text-right md:text-left">
                    {{
                        formatPrice(product.grossUnitPrice, product.currencyCode)
                    }}
                </div>
            </div>
            <div class="flex-row gap-4 w-full py-1 px-2 hidden print:flex">
                <div class="self-center w-full uppercase font-bold xl:hidden print:hidden">
                    {{
                        trans(
                            "cart_summary_gross_unit_price_with_profit",
                            "Gross unit price with profit",
                            "quote"
                        )
                    }}
                </div>
                <div class="w-full hidden text-right md:text-left print:flex self-center">
                    {{
                        formatPrice(
                            product.grossUnitPriceWithProfit,
                            product.currencyCode
                        )
                    }}
                </div>
            </div>
            <div class="flex flex-row gap-4 w-full py-1 px-2 print:hidden">
                <div class="self-center w-full uppercase font-bold xl:hidden">
                    {{
                        trans(
                            "cart_summary_profit_percentage",
                            "Profit percentage",
                            "quote"
                        )
                    }}
                </div>
                <div class="flex w-full justify-end md:justify-start">
                    <shop-input
                        v-model="product.profitPercentage"
                        type="number"
                        border
                        class="max-w-[100px]"
                        @change="
                            profitPercentageChanged(
                                Number($event.target.value),
                                product
                            )
                        "
                    />
                    <div
                        class="bg-blue text-white font-bold flex items-center self-center p-[10px] rounded-r-lg h-12 border-1 border-blue"
                    >
                        %
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-4 w-full py-1 px-2 print:hidden">
                <div class="self-center w-full uppercase font-bold xl:hidden">
                    {{
                        trans(
                            "cart_summary_gross_unit_price_with_profit",
                            "Gross unit price with profit",
                            "quote"
                        )
                    }}
                </div>
                <div class="flex w-full justify-end md:justify-start">
                    <shop-input
                        v-model="product.grossUnitPriceWithProfit"
                        type="number"
                        border
                        class="max-w-[136px]"
                        @change="
                            grossUnitPriceWithProfitChanged(
                                $event.target.value,
                                product
                            )
                        "
                    />
                </div>
            </div>
            <div class="flex flex-row gap-4 w-full py-1 px-2">
                <div
                    class="self-center w-full uppercase font-bold xl:hidden print:hidden xl:text-right"
                >
                    {{
                        trans(
                            "cart_summary_total_gross",
                            "Total gross",
                            "quote"
                        )
                    }}
                </div>
                <div
                    class="self-center w-full text-right md:text-left xl:text-right"
                >
                    {{
                        formatPrice(
                            calculateGrossValue(product),
                            product.currencyCode
                        )
                    }}
                </div>
            </div>
        </div>

        <div class="mb-3 border-t-2 border-b-2 border-[#929292]">
            <div class="flex py-[6px]">
                <div class="grow font-bold">
                    {{
                        trans(
                            "cart_summary_gross_total",
                            "Gross total",
                            "quote"
                        )
                    }}
                </div>
                <div
                    class="flex-[0_0_150px] text-right font-bold text-[18px] print:text-[14px]"
                >
                    {{ formattedTotalGrossValue }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, Ref, ref, watchEffect } from "vue";
import Cart from "../../store/carts/Cart";
import { useStore } from "vuex";
import {formatPrice, roundByCurrency} from "../../common/utils/shop";
import ShopInput from "../../components/Form/ShopInput.vue";
import QuoteProductInterface from "./QuoteProduct";
import CartProduct from "../../store/carts/CartProduct";
import { trans } from "../../common/i18n";
import { defineProps } from 'vue';

const prices = ref<{ [key: string]: { [key: string]: string }}>({});

const emit = defineEmits(["updateTotalGrossValue"]);

const props = defineProps({
    customPriceToggle: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const store = useStore();

const activeCart: ComputedRef<Cart | undefined> = computed(
    () => store.getters["carts/activeCart"]
);

const activeCartProducts: ComputedRef<Array<CartProduct> | undefined> =
    computed(() => {
        return activeCart.value?.products;
    });

const calculateGrossValue = (product: QuoteProductInterface): number => {
    return product.quantity * product.grossUnitPriceWithProfit;
};

const formattedTotalGrossValue: ComputedRef<string> = computed(() => {
    let totalGrossValue: number = 0;
    let currencyCode: string = "";

    quoteProducts.value.map((product: QuoteProductInterface) => {
        totalGrossValue += calculateGrossValue(product);
        currencyCode = product.currencyCode;
    });

    quoteProducts.value.forEach((product: any) => {
        prices.value[product.id] = {
            grossUnitPrice: formatPrice(product.grossUnitPriceWithProfit, product.currencyCode),
            totalGross: formatPrice(calculateGrossValue(product),product.currencyCode)
        };
    });

    prices.value['totalPrice'] = {
        totalPrice: formatPrice(totalGrossValue, currencyCode)
    };

    emit("updateTotalGrossValue");

    return formatPrice(totalGrossValue, currencyCode);
});

const quoteProducts: Ref<Array<QuoteProductInterface>> = ref([]);
const commonProfitPercentage: Ref<number> = ref(0);

const profitPercentageChanged = (
    profitPercentage: number,
    product: QuoteProductInterface
) => {
    // valtozik az egy termekre vonatkozo % merteke
    quoteProducts.value.map((quoteProduct: QuoteProductInterface) => {
        if (quoteProduct.id === product.id) {
            product.grossUnitPriceWithProfit = roundByCurrency(
                ((100 + profitPercentage) / 100) * product.grossUnitPrice,
                quoteProduct.currencyCode
            );
            product.profitPercentage = profitPercentage;
        }

        return product;
    });
};

const grossUnitPriceWithProfitChanged = (
    grossUnitPriceWithProfit: number,
    product: QuoteProductInterface
) => {
    // valtozik az osszeg, egy adott termeknel
    quoteProducts.value.map((quoteProduct: QuoteProductInterface) => {
        if (quoteProduct.id === product.id) {
            // itt kell kerekiteni a megengedett szamra
            product.grossUnitPriceWithProfit = roundByCurrency(grossUnitPriceWithProfit, quoteProduct.currencyCode);
            let profitPercentage: number =
                (grossUnitPriceWithProfit / product.grossUnitPrice - 1) * 100;
            product.profitPercentage = Math.round(100 * profitPercentage) / 100;
        }

        return product;
    });
};

const applyCommonProfitPercentageOnAllRows = (): void => {
    // valtozik a minden termekre vonatkozo % merteke
    quoteProducts.value.map((quoteProduct: QuoteProductInterface) => {
        quoteProduct.grossUnitPriceWithProfit = roundByCurrency(
            ((100 + commonProfitPercentage.value) / 100) * quoteProduct.grossUnitPrice,
            quoteProduct.currencyCode
        );
        quoteProduct.profitPercentage = commonProfitPercentage.value;

        return quoteProduct;
    });
};

const hasTyreLabeling = (): boolean => {
    return quoteProducts.value.some(product => {
        return product.fuelEfficiency || product.noiseMeasurementValue || product.wetGripClass;
    });
};

const calculateGrossPrice = (priceNet: number, priceCustomNet: number, vatPercent: number): number => {
    if (props.customPriceToggle) {
        return priceCustomNet * ((100 + vatPercent) / 100);
    } else {
        return priceNet * ((100 + vatPercent) / 100);
    }
}

watchEffect(() => {
    if (activeCartProducts.value !== undefined) {
        quoteProducts.value = [];

        activeCartProducts.value?.forEach((value: CartProduct) => {
            quoteProducts.value.push({
                id: Number(value.id),
                name: value.getProductName(),
                grossUnitPrice: roundByCurrency(
                    calculateGrossPrice(
                        value.priceNet + value.priceEco,
                        value.priceCustomNet + value.priceEco,
                        value.priceVatPercent
                    ),
                    value.currencyCode
                ),
                quantity: value.quantity,
                currencyCode: value.currencyCode,
                profitPercentage: 0,
                grossUnitPriceWithProfit: roundByCurrency(
                    calculateGrossPrice(
                        value.priceNet + value.priceEco,
                        value.priceCustomNet + value.priceEco,
                        value.priceVatPercent
                    ),
                    value.currencyCode
                ),
                size: value.getDetailsSize(),
                priceVatPercent: value.priceVatPercent,
                fuelEfficiency: value.fuelEfficiency,
                wetGripClass: value.wetGripClass,
                noiseMeasurementValue: value.noiseMeasurementValue
            });
        });
    }
});

defineExpose({
  prices
});

</script>
<style scoped>
@media print {
    .xl\:flex-row {
        flex-direction: row;
    }
}
</style>
